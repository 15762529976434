import "./Sidebar.scss";

export default function Sidebar({ content, setContent, setMenu }) {
  const screen = window.screen.width;
  const profile = sessionStorage.getItem("profile");

  function pageDashboard() {
    setContent("dashboard");

    if (screen < 992) {
      setMenu(false);
    }
  }

  function pageImport() {
    setContent("import");

    if (screen < 992) {
      setMenu(false);
    }
  }

  function pageTicketValidation() {
    setContent("ticket");

    if (screen < 992) {
      setMenu(false);
    }
  }

  return (
    <aside className="sidebar adt-col-3" id="sideBar">
      <nav>
        <ul>
          {profile === "A" ? (
            <>
              <li>
                <button
                  className="adt-btn adt-btn--red_reversed"
                  onClick={() => pageTicketValidation()}
                >
                  Consultar cupom
                </button>
              </li>
              <li>
                <button
                  className=" adt-btn  adt-btn--red_reversed"
                  onClick={() => pageDashboard()}
                >
                  Meus números
                </button>
              </li>
              <li>
                <button
                  className="adt-btn adt-btn--red_reversed"
                  onClick={() => pageImport()}
                >
                  Realizar importação
                </button>
              </li>
            </>
          ) : (
            <li>
              <button
                className="adt-btn adt-btn--red_reversed"
                onClick={() => pageTicketValidation()}
              >
                Consultar cupom
              </button>
            </li>
          )}
        </ul>
      </nav>
    </aside>
  );
}
