import { BASE_URL } from "../constants/urls";
import Swal from "sweetalert2";
import axios from "axios";

export const ImportService = (body) => {
  let config = {
    method: 'post',
    url: `${BASE_URL}/import`,
    headers: { 
      'Authorization': 'Bearer QWRlbWljb24yMDIy', 
      'Content-Type': 'application/json'
    },
    data : JSON.stringify(body)
  }
  
  return axios(config)
  .then(async (res) => {
    return await res.data
  })
  .catch(() => {
    Swal.fire({
      icon: "error",
      title: "Ops...",
      confirmButtonColor: "#B2342A",
      text: "Tivemos um problema com a importação!",
    });
  })
}
