export const mask = (data) => {
  data = data.replace(/\D/g, "")

  if (data.length <= 11) {
    data = data.replace(/(\d{3})(\d)/, "$1.$2")
    data = data.replace(/(\d{3})(\d)/, "$1.$2")
    data = data.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  } else {
    data = data.replace(/^(\d{2})(\d)/, "$1.$2")
    data = data.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    data = data.replace(/\.(\d{3})(\d)/, ".$1/$2")
    data = data.replace(/(\d{4})(\d)/, "$1-$2")
  }

  return data
}