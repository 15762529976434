import { BASE_URL } from "../constants/urls";
import Swal from "sweetalert2";
import axios from "axios";

export const cpfOrCnpjValidation = (cpfOrCnpj, setData, setExpired) => {
  const cpfOrCnpjFormated = cpfOrCnpj.replace(/\D/g, '')

  const body = {
    cpfcnpj: cpfOrCnpjFormated,
  };
  axios
    .post(`${BASE_URL}/consulta`, body)
    .then((res) => {
      setData(res.data.row)
      setExpired(res.data.row.expired)
      console.log(res.data.row.expired)
    })
    .catch(() => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#B7312C",
        text: "CPF ou CNPJ inválidos!",
      });
    });
};
