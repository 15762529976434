import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faBars,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";

import "./Topbar.scss";
import logo from "../../../assets/images/ademicon-blank.png";
import { goToLogin } from "../../../router/coordinator";
import { useNavigate } from "react-router-dom";

export default function Topbar({menu, setMenu}) {
  const navigate = useNavigate();
  const screen = window.screen.width

  useEffect(() => {
    if (screen < 992) {
      if (menu === true) {
        document.getElementById("sideBar").style.display = "block";
      } else {
        document.getElementById("sideBar").style.display = "none";
      }
    } else {
      document.getElementById("sideBar").style.display = "block";
    }
  }, [menu]);

  const logout = () => {
    sessionStorage.removeItem("isLogged");
    sessionStorage.removeItem("profile");
    goToLogin(navigate);
  };

  return (
    <header className="topbar">
      {menu === true ? (
        <FontAwesomeIcon
          icon={faXmark}
          className="hamburger"
          onClick={(e) => setMenu((state) => !state)}
        />
      ) : (
        <FontAwesomeIcon
          icon={faBars}
          className="hamburger"
          onClick={(e) => setMenu((state) => !state)}
        />
      )}

      <img src={logo} alt="Logo" />

      <FontAwesomeIcon icon={faSignOutAlt} onClick={logout} />
    </header>
  );
}
