import { Chart } from "react-google-charts";
import { DashboardService } from "../../services/Dashboard";

import "./Dashboard.scss";
  
let data = [[
  "Element",
  "Importações"
]]

let options = {
  title: "Quantidade de importações por mês",
  bar: { groupWidth: "25%" },
  legend: { position: "none" },
}

function loaderChart(){

  DashboardService().then((res) => {
    
    data = [];

    data.push([ "Element", "Importações" ])
  
    res.query.map((el) => {
      data?.push([el.month,parseInt(el.qtd)])
    }) 
  
  })
  
}

export default function Dashboard() {
      
  loaderChart()
  
  return (
    <div className="dashboard">
      <section className="card-header">
        <h4 className="title">
          <span>Ano:</span> 2022
        </h4>
        <Chart   
          chartType="BarChart" 
          width="100%"
          height="400px"
          data={data}
          options={options}
          redraw={true}
        />
      </section>
    </div>
  )
}
