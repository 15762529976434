import { Route, Routes, BrowserRouter } from "react-router-dom";

import Template from '../app/pages/Template'
import Login from '../app/pages/Login'

export default function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Template/>} exact />
        <Route path="/login" element={<Login/>} />
        <Route path="*" element={<Template />} />
      </Routes>
    </BrowserRouter>
  )
}