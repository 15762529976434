import './AlertSuccess.scss'

export function AlertSuccess({ content, clear, setContent }) {
  const pageDashboard = () => {
    setContent("dashboard");
  }
  
  return (
    <>
      <section id='Alert'>
        <div>
          <h1>Obrigado</h1>
          <h5>Arquivo importado com sucesso.</h5>
          <b>{ content.info }</b>
          <br/>
          <br/>
          
          {/* <Link to='/' onClick={() => clear()}>Ir para o início</Link> */}
          <p onClick={() => pageDashboard()}>Ir para o início</p>
        </div>
      </section> 
    </>
  )
}