import React, { useState } from "react";
import { cpfOrCnpjValidation } from "../../services/ticket";
import { mask } from "../../helper/mask";
import Swal from "sweetalert2";
import "./TicketValidation.scss";



export default function TicketValidation() {
  const [expired, setExpired] = useState("");
  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [data, setData] = useState([]);

  function handleChangeMask(event) {
    setCpfOrCnpj(mask(event))
  }


  const onSubmitForm = (event) => {
    event.preventDefault();
    if (!cpfOrCnpj) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#b2342a",
        text: "Por favor, preencher o campo!",
      });
      return;
    }

    cpfOrCnpjValidation(cpfOrCnpj, setData, setExpired);
  };

  return (
    <div className="TicketValidation">
      <section>
        <form onSubmit={onSubmitForm}>
          <div>
            <h4>Validar Cupom</h4>
            <div className="info">
              <input
                className="adt-form--input"
                type="text"
                value={cpfOrCnpj}
                id="cpfOrCnpj"
                name="cpfOrCnpj"
                size={18}
                maxLength={18}
                onChange={(e) => (handleChangeMask(e.target.value))}
                pattern="([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})"
                placeholder="Digite aqui o cpf/cnpj do cliente"
              ></input>
            </div>
            <button className="adt-btn adt-btn--red">Consultar</button>
          </div>
        </form>
        {expired !== '' ?
        <div className="message">
          {expired === true ? (
            <div className="success">
              <p>Cliente: {data.nome}</p>
              <p>Cupom válido!<br/> Esse cupom expira em: {data.expired_at}</p>
            </div>
          ) : (
            <div className="error">
              <p>Cliente: {data.nome}</p>
              <p>Esse cupom expirou em: {data.expired_at}</p>
            </div>
          )}
        </div> :
        '' }
      </section>
    </div>
  );
}
