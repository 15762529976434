import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { faFileCsv } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Import.scss'
import { ImportService } from '../../services/Import'
import { AlertSuccess } from '../AlertSuccess/AlertSuccess'

export default function Import({setContent}) {

  const [ message, setMessage] = useState('Selecione ou arraste um arquivo em .csv')
  const [ classes, setClasses ] = useState('dropzone')
  const [ file, setFile ] = useState()
  const [ alert, setAlert ] = useState()

  useEffect(() => {
    setAlert(false)
  },[])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {'text/csv': '.csv'},
    validator: validator
  })
  
  function validator(file) {
    setClasses('dropzone')
    setMessage('Selecione ou arraste um arquivo em .csv')
    
    if(file.type !== 'text/csv') {
    
      setMessage('Somente arquivos no formato .csv')
      setClasses('dropzone dropzone-danger')
    
    } else if(file.size > 1000000) {
    
      setMessage('Tamanho máximo permitido 1MB')
      setClasses('dropzone dropzone-danger')
    
    } else {
      setMessage(file.name)
      setFile(file)
    }
  }

  function handleSubmit() {
    let reader = new FileReader()
    reader.onload = function(e) {
      ImportService({
        csv: e.target.result.split(',')[1],
        fileName: file.name,
        fileModifiedDate: file.lastModifiedDate
      }).then((res) => {
        setAlert(res)
      })
    }
    reader.readAsDataURL(file)
  }

  function clear() {
    setAlert(false)
    setMessage('Selecione ou arraste um arquivo em .csv')
    setFile({})
  }

  return (
    <>
      <section id='import'>
        <div>
          <div {...getRootProps({ className: classes })}  >
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={faFileCsv} /> 
            
            { message }

          </div>
        </div>
        <button className='adt-btn adt-btn--red' onClick={() => handleSubmit()}>
          Enviar
          {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
          </circle>
          </svg>   */}
        </button>
      </section>
      { alert && <AlertSuccess content={alert} clear={clear} setContent={setContent}/> }
    </>
  )
}