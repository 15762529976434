import { BASE_URL } from "../constants/urls";
import Swal from "sweetalert2";
import axios from "axios";

export const DashboardService = async () => {
  let config = {
    method: 'get',
    url: `${BASE_URL}/get_contemplados`,
    headers: { 
      'Authorization': 'Bearer QWRlbWljb24yMDIy', 
      'Content-Type': 'application/json'
    }
  }
  
  try {
    const res = await axios(config)
    return res.data
  } catch {
    Swal.fire({
      icon: "error",
      title: "Ops...",
      confirmButtonColor: "#B2342A",
      text: "Tivemos um problema com a importação!",
    })
  }
}
