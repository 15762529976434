import React, { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/login";
import Logo from "../../../assets/images/ademicon-logo-white.png";
import "./Login.scss";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const onSubmitForm = (event) => {
    event.preventDefault();

    if (!email || !password) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#b2342a",
        text: "Por favor, preencher todos os campos!",
      });
      return;
    }

    const body = {
      user: email,
      password: password,
    };

    login(body, navigate);
  };

  return (
    <div className="login">
      <section>
        <img src={Logo} alt="Logo Ademicon" />

        <form onSubmit={onSubmitForm}>
          <div>
            <label className="adt-form--label">E-mail</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder="Email:"
              className="adt-form--input"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
          </div>
          <div>
            <label className="adt-form--label">Senha</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Senha:"
              className="adt-form--input"
            />
          </div>
          <button className="adt-btn adt-btn--red_reversed">ACESSAR</button>
        </form>
      </section>

      <ul className="box-area">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default LoginPage;
