import { useState } from "react";
import { Navigate } from "react-router-dom";

import "./Template.scss";

import Topbar from "../../components/Topbar";
import Sidebar from "../../components/Sidebar";
import Dashboard from "../../components/Dashboard/Dashboard";
import Import from "../../components/Import/Import";
import TicketValidation from "../../components/TicketValidation/TicketValidation";

export default function Template() {
  const isLogged = sessionStorage.getItem("isLogged");
  const profile = sessionStorage.getItem("profile");
  const [menu, setMenu] = useState(false);
  const [content, setContent] = useState("");

  const searchPage = () => {
    switch (content) {
      case "dashboard":
        return <Dashboard />;
      case "import":
        return <Import setContent={setContent}/>;
      case "ticket":
        return <TicketValidation />;
      default:
        const check = profile === 'K' ? <TicketValidation/> : <Dashboard />
        return check;
    }
  };
  return (
    <>
      {!isLogged ? (
        <Navigate to="/login" />
      ) : (
        <section id="main">
          <Topbar menu={menu} setMenu={setMenu}/>
          <article className="adt-row">
            <Sidebar content={content} setContent={setContent} setMenu={setMenu}/>
            <aside className="adt-col-9">{searchPage()}</aside>
          </article>
        </section>
      )}
    </>
  );
}
