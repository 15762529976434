import { BASE_URL } from "../constants/urls";
import Swal from "sweetalert2";
import axios from "axios";
import { goToHome } from "../../router/coordinator";

export const login = (body, navigate) => {
  
  var config = {
    method: 'post',
    url: `${BASE_URL}/oauth`,
    headers: { 
      'Authorization': 'Bearer QWRlbWljb24yMDIy', 
      'Content-Type': 'application/json'
    },
    data : JSON.stringify(body)
  };
  
  axios(config)
  .then((res) => {
    console.log(res.data.user)
    sessionStorage.setItem("profile", res.data.user.perfil);
    sessionStorage.setItem("isLogged", 'true');
    goToHome(navigate)
  })
  .catch(() => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      confirmButtonColor: "#B2342A",
      text: "Erro ao logar, verifique os campos e tente novamente!",
    });
  });

};
